import { createReducer, current } from '@reduxjs/toolkit';
import {
  IOrderItem,
  IPaymentInstruction,
  UsablePaymentInformationEntity,
} from '../../../../_foundation/interface/Cart/IOrder';
import {
  IAddPickupPersonPayload,
  IPickupPersonState,
} from '../../../../_foundation/interface/PickupPerson/IPickupPerson';
import ICartProductsResponse from '../../../../_foundation/interface/Responses/ICartProductsResponse';
import ICartResponse, {
  IPaymentInstructionItem,
} from '../../../../_foundation/interface/Responses/ICartResponse';
import IGetUsableShippingResponse from '../../../../_foundation/interface/Responses/IGetUsableShippingResponse';
import { IShippingMethods } from '../../../../_foundation/interface/Responses/IShippingInfoResponse';
import { IGetUsableShippingState } from '../../../../_foundation/interface/Shipping/IUsableShipping';
import { IDeliveryOptionAndStorePickUp } from '../../../../_foundation/interface/StoreLocator/IStoreLocator';
import { IStorePickupState } from '../../../../_foundation/interface/StorePickup/IStorePickup';
import { UPDATE_USABLE_PAYMENT_INFO_ACTION } from '../../../../redux/actions/order.actions';
import { UPDATE_PAYMENT_METHOD_ACTION } from '../../../../redux/actions/payment.action';
import {
  ADD_PICKUP_PERSON_SUCCESS_ACTION,
  DELETE_PICKUP_PERSON_SUCCESS_ACTION,
  GET_PICKUP_PERSON_REVIEW_SUCCESS_ACTION,
  GET_PICKUP_PERSON_SUCCESS_ACTION,
  UPDATE_PICKUP_PERSON_SUCCESS_ACTION,
} from '../../../../redux/actions/pickupPerson.actions';
import {
  DELIVERY_ASSIGN_STORE_SUCCESS_ACTION,
  PAYMENT_ASSIGN_STORE_SUCCESS_ACTION,
} from '../../../../redux/actions/storePickup.actions';
import { checkTruthy, setLocalStorage } from '../../../../utils/utils';
import { PAYMENTTYPE } from '../../CheckoutPayment/ICheckoutPayment';
import {
  ICardinalOrderResponse,
  ICheckoutState,
} from '../../interface/ICheckoutState';
import {
  FETCH_CHECKOUT_CART_DETAILS_REVIEW,
  UPDATE_EMAIL_OPTIN_STATUS,
} from '../action-types/checkout.action-types';
import {
  CARDINAL_ORDER_REQUEST_SUCCESS_ACTION,
  CARD_DENIED_ACTION,
  DELIVERY_PAGE_API_LOADING,
  DELIVERY_STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS_ACTION,
  DELIVERY_USABLE_SHIPPING_INFO_LOADING_ACTION,
  DELIVERY_USABLE_SHIPPING_INFO_SUCCESS_ACTION,
  ENABLE_DELIVERY_CONTINUE_ACTION,
  ENABLE_PAYMENT_CONTINUE_ACTION,
  FETCH_CART_PRODUCTS_REVIEW_ACTION,
  FETCH_CHECKOUT_CART_DETAILS_DELIVERY,
  FETCH_CHECKOUT_CART_DETAILS_PAYMENT,
  PAYMENT_STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS_ACTION,
  PAYMENT_USABLE_SHIPPING_INFO_SUCCESS_ACTION,
  PAYPAL_PROCESS_LOADING_ACTION,
  RESET_ASSIGN_STORE_ACTION,
  RESET_DELIVERY_STEP_ACTION,
  RESET_PAYMENT_STEP_ACTION,
  RESET_RESTRICTED_PRODUCTS_ACTION,
  RESET_REVIEW_STEP_ACTION,
  RESET_SHIPPING_OPTIONS_ACTION,
  RESTRICTED_CART_PRODUCTS_SUCCESS_ACTION,
  REVIEW_USABLE_SHIPPING_INFO_SUCCESS_ACTION,
  SELECTED_ADDRESS_NICK_NAME_ACTION,
  SET_UPDATE_DELIVERY_SHIPPING_METHOD_LOADING_ACTION,
  UPDATE_DELIVERY_SHIPPING_METHOD_DATA_ACTION,
  UPDATE_REVIEW_SHIPPING_METHOD_DATA_ACTION,
} from '../actions/checkout.actions';
import { PAYPAL } from '../../../../_foundation/constants/payment';

const initCartState: ICartResponse = {
  adjustment: [],
  buyerId: '',
  channel: {
    channelIdentifer: {
      channelName: '',
      uniqueID: '',
    },
    description: {
      language: '',
      value: '',
    },
    userData: null,
  },
  grandTotalCurrency: '',
  grandTotal: '',
  lastUpdateDate: '',
  locked: '',
  mergeCart: '',
  orderId: '',
  orderItem: [],
  orderSplitWarning: false,
  orderStatus: '',
  paymentInstruction: [],
  prepareIndicator: '',
  recordSetComplete: '',
  recordSetCount: '',
  recordSetStartNumber: '',
  recordSetTotal: '',
  resourceId: '',
  resourceName: '',
  shipAsComplete: '',
  storeNameIdentifier: '',
  storeUniqueID: '',
  totalAdjustment: '',
  totalAdjustmentCurrency: '',
  totalProductPrice: '',
  totalProductPriceCurrency: '',
  totalSalesTax: '',
  totalSalesTaxCurrency: '',
  totalShippingCharge: '',
  totalShippingChargeCurrency: '',
  totalShippingTax: '',
  totalShippingTaxCurrency: '',
  x_isPersonalAddressesAllowedForShipping: '',
  x_isPurchaseOrderNumberRequired: '',
  x_trackingIds: '',
  x_field1: '',
  x_physicalStoreName: '',
  x_physicalStoreId: '',
  x_carrierEstimateDelivery: '',
  estimatedDelivery: '',
  x_poNumber: '',
  x_shipModeId: '',
  promotionCode: [],
  x_dropShippedOnly: '',
  x_iomStoreId: '',
  x_carrierCode: '',
};

const initDeliveryStorePickUp: IDeliveryOptionAndStorePickUp = {
  deliveryOptionDetails: [],
  isNoStoreAvailable: false,
  loading: true,
  storePickUpDetails: [],
};

const initStorePickUp: IStorePickupState = {
  isBOPIS: false,
  isFSD: false,
  storeLocatorId: '',
  storeName: '',
};

const initUsableShippingInfo: IGetUsableShippingState = {
  loading: true,
  restrictedItems: [],
  usableShippingMethodData: [],
  usableShippingMode: [],
  sameDayDelivery: false,
  isBopisEligible: false,
  physicalStoreId: '',
  backOrderedProducts: [],
  liftgateservice: false,
  dropShippedOnly: false,
  iomStoreId: '',
  liftgatecharges: 0,
};

const initShippingMethodsState: IShippingMethods = {
  cost: '',
  method: '',
  shippingId: '',
  time: '',
  shipModeCode: '',
  iomStoreId: '',
};

const initialCheckoutState: ICheckoutState = {
  deliveryStep: {
    cartItems: initCartState,
    deliveryStorePickup: initDeliveryStorePickUp,
    pickupPerson: [],
    restrictedProducts: [],
    storePickUp: initStorePickUp,
    usableShippingInfo: initUsableShippingInfo,
    shippingMethods: initShippingMethodsState,
    isContinueEnabled: false,
    cartItemsLoading: true,
    restrictedProductsLoading: true,
    selectedAddressNickname: '',
    giftCardDeductedAmount: 0,
    x_physicalStoreName: '',
    x_field1: '',
    updateShippingMethodLoading: false,
    isAddressUpdate: false,
    isApiRunning: false,
  },
  paymentStep: {
    isContinueEnabled: false,
    cartItems: initCartState,
    cartItemsLoading: true,
    allowMultiplePayments: false,
    giftCardDeductedAmount: -1,
    storePickUp: initStorePickUp,
    usablePaymentInfo: {
      loaded: false,
      paymentInfo: [],
    },
    deliveryStorePickup: initDeliveryStorePickUp,
    usableShippingInfo: initUsableShippingInfo,
    paymentMethod: '',
    cartDenied: false,
    x_physicalStoreName: '',
    x_field1: '',
  },
  reviewStep: {
    isContinueEnabled: false,
    cartItems: initCartState,
    cartItemsLoading: true,
    noOfCartItems: 0,
    cartProducts: [],
    usableShippingInfo: initUsableShippingInfo,
    pickupPerson: [],
    shippingMethods: initShippingMethodsState,
    cardinalOrder: {
      ACSUrl: '',
      PaReq: '',
    },
    giftCardDeductedAmount: 0,
    freeItems: {},
    storePickUp: initStorePickUp,
    x_physicalStoreName: '',
    x_field1: '',
    x_physicalStoreId: '',
    emailOptin: true,
    processing: false,
  },
};

/**
 * @interface ICalculateGiftCardBalance
 */
interface ICalculateGiftCardBalance {
  paymentInstruction: IPaymentInstructionItem[];
  grandTotal: string;
  giftCardDeductedAmount: number;
  allowMultiplePayments?: boolean;
}

/**
 * @method calculateGiftCardBalance Calculates current giftcard deducted amount
 * and also determines whether to allow multiple payments.
 */
const calculateGiftCardBalance = ({
  giftCardDeductedAmount,
  grandTotal,
  paymentInstruction,
  allowMultiplePayments = false,
}: ICalculateGiftCardBalance) => {
  /**
   * This block of code is responsible for calculating the amount
   * deducted from grand total after applying gift card.
   */
  giftCardDeductedAmount = -1;

  /**
   * By default allow multiple payments is set to false.
   *
   * This allows the regular payment flow by deleting the pi and add the new pi.
   */
  allowMultiplePayments = false;

  if (paymentInstruction && paymentInstruction.length !== 0) {
    /**
     * Check if the payment instruction already has any gift card applied.
     */
    const hasGiftCard = paymentInstruction.findIndex(
      ({ payMethodId }: IPaymentInstruction) =>
        payMethodId.toLowerCase() === PAYMENTTYPE.GIFT_CARD.toLowerCase()
    );

    if (hasGiftCard !== -1) {
      allowMultiplePayments = true;

      let deductedAmount: number = 0;

      paymentInstruction.forEach(
        ({ piAmount, payMethodId }: IPaymentInstruction) =>
          payMethodId.toLowerCase() === PAYMENTTYPE.GIFT_CARD.toLowerCase() &&
          (deductedAmount = Number(piAmount) + deductedAmount)
      );

      /**
       * Calculate the difference amount between grand total and the gift card amounts.
       */
      giftCardDeductedAmount = Math.abs(
        Number(grandTotal) - Number(deductedAmount)
      );
    }
  }

  /**
   * If the gift card pays for the entire grandTotal disable multiple payments.
   */
  if (giftCardDeductedAmount === 0) {
    allowMultiplePayments = false;
  }

  return { giftCardDeductedAmount, allowMultiplePayments };
};

const checkoutReducer = createReducer(initialCheckoutState, (builder) => {
  builder.addCase(
    FETCH_CHECKOUT_CART_DETAILS_DELIVERY,
    (state: ICheckoutState, action: any) => {
      const cartItems = action.payload as ICartResponse;

      const currentState = current(state);

      const { grandTotal, paymentInstruction, x_physicalStoreName, x_field1 } =
        cartItems;

      const { giftCardDeductedAmount } = calculateGiftCardBalance({
        giftCardDeductedAmount: -1,
        grandTotal,
        paymentInstruction,
      });

      const updatedState: ICheckoutState = {
        ...currentState,
        deliveryStep: {
          ...currentState.deliveryStep,
          cartItems,
          cartItemsLoading: false,
          giftCardDeductedAmount,
          x_physicalStoreName,
          x_field1,
        },
      };

      return { ...updatedState };
    }
  );

  builder.addCase(
    DELIVERY_PAGE_API_LOADING,
    (state: ICheckoutState, action: any) => {
      const currentState = current(state);

      const updatedState: ICheckoutState = {
        ...currentState,
        deliveryStep: {
          ...currentState.deliveryStep,
          isApiRunning: action.payload,
        },
      };

      return { ...updatedState };
    }
  );

  builder.addCase(
    UPDATE_DELIVERY_SHIPPING_METHOD_DATA_ACTION,
    (state: ICheckoutState, action: any) => {
      const shippingMethodData = action.payload as IShippingMethods;

      if (shippingMethodData) {
        const { method, time, cost, shippingId, shipModeCode, iomStoreId } =
          shippingMethodData;

        const shippingMethods: IShippingMethods = {
          method,
          time,
          cost,
          shippingId,
          shipModeCode,
          iomStoreId,
        };

        const currentState = current(state);

        const updatedState: ICheckoutState = {
          ...currentState,
          deliveryStep: {
            ...currentState.deliveryStep,
            shippingMethods,
          },
        };

        let storePickupflag = '';

        storePickupflag = updatedState.deliveryStep.storePickUp.isBOPIS
          ? 'BOPIS'
          : updatedState.deliveryStep.storePickUp.isFSD
          ? 'FSD'
          : '';

        setLocalStorage('storePickup', storePickupflag);

        return { ...updatedState };
      }
    }
  );

  builder.addCase(
    SET_UPDATE_DELIVERY_SHIPPING_METHOD_LOADING_ACTION,
    (state: ICheckoutState, action: any) => {
      const currentState = current(state);

      const updatedState: ICheckoutState = {
        ...currentState,
        deliveryStep: {
          ...currentState.deliveryStep,
          updateShippingMethodLoading:
            action.payload.updateShippingMethodLoading,
          isAddressUpdate: action.payload.isAddressUpdate,
        },
      };

      return { ...updatedState };
    }
  );

  builder.addCase(
    SELECTED_ADDRESS_NICK_NAME_ACTION,
    (state: ICheckoutState, action: any) => {
      const currentState = current(state);

      const updatedState: ICheckoutState = {
        ...currentState,
        deliveryStep: {
          ...currentState.deliveryStep,
          selectedAddressNickname: action.payload,
        },
      };

      return { ...updatedState };
    }
  );

  /**
   * CASE GET_PICKUP_PERSON_ACTION
   * Fetches all the pickup person details
   */
  builder.addCase(
    GET_PICKUP_PERSON_SUCCESS_ACTION,
    (state: ICheckoutState, action: any) => {
      const { pickupPerson } = action.payload as IPickupPersonState;

      const currentState = current(state);

      if (pickupPerson && Array.isArray(pickupPerson)) {
        const updatedState: ICheckoutState = {
          ...currentState,
          deliveryStep: {
            ...currentState.deliveryStep,
            pickupPerson,
          },
        };

        return {
          ...updatedState,
        };
      } else {
        const updatedState: ICheckoutState = {
          ...currentState,
          deliveryStep: {
            ...currentState.deliveryStep,
            pickupPerson: [],
          },
        };

        return {
          ...updatedState,
        };
      }
    }
  );

  /**
   * CASE ADD_PICKUP_PERSON_SUCCESS_ACTION
   * Adds a pick up person.
   */
  builder.addCase(
    ADD_PICKUP_PERSON_SUCCESS_ACTION,
    (state: ICheckoutState, action: any) => {
      const pickupPerson = action.payload as IAddPickupPersonPayload;

      const currentState = current(state);

      const updatedState: ICheckoutState = {
        ...currentState,
        deliveryStep: {
          ...currentState.deliveryStep,
          pickupPerson: [
            ...currentState.deliveryStep.pickupPerson,
            pickupPerson,
          ],
        },
      };

      return { ...updatedState };
    }
  );

  /**
   * CASE DELETE_PICKUP_PERSON_SUCCESS_ACTION
   * Deletes a pickup person
   */
  builder.addCase(
    DELETE_PICKUP_PERSON_SUCCESS_ACTION,
    (state: ICheckoutState, action: any) => {
      const { nickName } = action.payload as IAddPickupPersonPayload;

      const currentState = current(state);

      const updatedState: ICheckoutState = {
        ...currentState,
        deliveryStep: {
          ...currentState.deliveryStep,
          pickupPerson: currentState.deliveryStep.pickupPerson.filter(
            (person) => person.nickName !== nickName
          ),
        },
      };

      return {
        ...updatedState,
      };
    }
  );

  /**
   * CASE UPDATE_PICKUP_PERSON_SUCCESS_ACTION
   * Updates a pickup person
   */
  builder.addCase(
    UPDATE_PICKUP_PERSON_SUCCESS_ACTION,
    (state: ICheckoutState, action: any) => {
      const pickupPerson = action.payload as IAddPickupPersonPayload;

      const currentState = current(state);

      const updatedState: ICheckoutState = {
        ...currentState,
        deliveryStep: {
          ...currentState.deliveryStep,
          pickupPerson: [
            ...currentState.deliveryStep.pickupPerson.filter(
              (person) => person.nickName !== pickupPerson.nickName
            ),
            pickupPerson,
          ],
        },
      };

      return {
        ...updatedState,
      };
    }
  );

  /**
   * @action DELIVERY_STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS_ACTION
   * Fetches all the store Pickup inventory details for a order
   */
  builder.addCase(
    DELIVERY_STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS_ACTION,
    (state: ICheckoutState, action: any) => {
      const { PhysicalStore } = action.payload;

      if (PhysicalStore) {
        const currentState = current(state);

        const updatedState: ICheckoutState = {
          ...currentState,
          deliveryStep: {
            ...currentState.deliveryStep,
            deliveryStorePickup: {
              ...currentState.deliveryStep.deliveryStorePickup,
              storePickUpDetails: PhysicalStore,
              loading: false,
            },
          },
        };

        return {
          ...updatedState,
        };
      }
    }
  );

  /**
   * CASE ASSIGN_STORE_SUCCESS_ACTION
   * Assign a selected pickup store
   */
  builder.addCase(
    DELIVERY_ASSIGN_STORE_SUCCESS_ACTION,
    (state: ICheckoutState, action: any) => {
      const storePickUp = action.payload as IStorePickupState;

      const currentState = current(state);

      const updatedState: ICheckoutState = {
        ...currentState,
        deliveryStep: {
          ...currentState.deliveryStep,
          storePickUp,
        },
        paymentStep: {
          ...currentState.paymentStep,
          storePickUp,
        },
        reviewStep: {
          ...currentState.reviewStep,
          storePickUp,
        },
      };

      let storePickupflag = '';

      storePickupflag = updatedState.deliveryStep.storePickUp.isBOPIS
        ? 'BOPIS'
        : updatedState.deliveryStep.storePickUp.isFSD
        ? 'FSD'
        : '';

      setLocalStorage('storePickup', storePickupflag);

      return {
        ...updatedState,
      };
    }
  );

  /**
   * @action DELIVERY_USABLE_SHIPPING_INFO_SUCCESS_ACTION
   * Fetches all the usable shipping info
   */
  builder.addCase(
    DELIVERY_USABLE_SHIPPING_INFO_SUCCESS_ACTION,
    (state: ICheckoutState, action: any) => {
      const response = action.payload as IGetUsableShippingResponse;

      const restrictedItems = response.restrictedItems
        ? response.restrictedItems
        : [];
      const currentState = current(state);

      if (response.orderItem[0] && response.orderItem[0]?.usableShippingMode) {
        const usableShippingMode = response.orderItem[0].usableShippingMode;

        const freightLiftgateCharge = usableShippingMode[0]
          .freightLiftgateCharge
          ? usableShippingMode[0].freightLiftgateCharge
          : '';

        const updatedState: ICheckoutState = {
          ...currentState,
          deliveryStep: {
            ...currentState.deliveryStep,
            usableShippingInfo: {
              ...currentState.deliveryStep.usableShippingInfo,
              usableShippingMode,
              loading: false,
              sameDayDelivery: response.sameDayDelivery,
              restrictedItems,
              isBopisEligible: response.isBopisEligible,
              physicalStoreId: response.physicalStoreId,
              backOrderedProducts: response.backOrderedProducts,
              liftgateservice: response.liftgateservice,
              dropShippedOnly: response.dropShippedOnly,
              liftgatecharges: Number(freightLiftgateCharge),
            },
            updateShippingMethodLoading: false,
            isAddressUpdate: false,
          },
        };

        return {
          ...updatedState,
        };
      }

      const updatedState: ICheckoutState = {
        ...currentState,
        deliveryStep: {
          ...currentState.deliveryStep,
          usableShippingInfo: {
            ...currentState.deliveryStep.usableShippingInfo,
            loading: false,
            sameDayDelivery: response.sameDayDelivery,
            liftgateservice: response.liftgateservice,
            dropShippedOnly: response.dropShippedOnly,
            restrictedItems,
          },
          updateShippingMethodLoading: false,
          isAddressUpdate: false,
        },
      };

      return {
        ...updatedState,
      };
    }
  );

  builder.addCase(
    DELIVERY_USABLE_SHIPPING_INFO_LOADING_ACTION,
    (state: ICheckoutState, action: any) => {
      const currentState = current(state);

      const updatedState: ICheckoutState = {
        ...currentState,
        deliveryStep: {
          ...currentState.deliveryStep,
          usableShippingInfo: {
            ...currentState.deliveryStep.usableShippingInfo,
            loading: action.payload,
          },
        },
      };

      return { ...updatedState };
    }
  );

  builder.addCase(
    RESTRICTED_CART_PRODUCTS_SUCCESS_ACTION,
    (state: ICheckoutState, action: any) => {
      const { contents } = action.payload as ICartProductsResponse;

      const currentState = current(state);

      const updatedState: ICheckoutState = {
        ...currentState,
        deliveryStep: {
          ...currentState.deliveryStep,
          restrictedProducts: contents,
          cartItemsLoading: false,
          restrictedProductsLoading: false,
        },
      };

      return {
        ...updatedState,
      };
    }
  );

  builder.addCase(RESET_RESTRICTED_PRODUCTS_ACTION, (state: ICheckoutState) => {
    const currentState = current(state);

    const updatedState: ICheckoutState = {
      ...currentState,
      deliveryStep: {
        ...currentState.deliveryStep,
        restrictedProducts: [],
        restrictedProductsLoading: true,
      },
    };

    return { ...updatedState };
  });

  builder.addCase(
    ENABLE_DELIVERY_CONTINUE_ACTION,
    (state: ICheckoutState, action: any) => {
      const currentState = current(state);

      const updatedState: ICheckoutState = {
        ...currentState,
        deliveryStep: {
          ...currentState.deliveryStep,
          isContinueEnabled: action.payload,
        },
      };

      return { ...updatedState };
    }
  );

  builder.addCase(RESET_SHIPPING_OPTIONS_ACTION, (state: ICheckoutState) => {
    const currentState = current(state);

    const updatedState: ICheckoutState = {
      ...currentState,
      deliveryStep: {
        ...currentState.deliveryStep,
        usableShippingInfo: initUsableShippingInfo,
      },
    };

    return { ...updatedState };
  });

  builder.addCase(RESET_DELIVERY_STEP_ACTION, (state: ICheckoutState) => {
    return { ...state, deliveryStep: initialCheckoutState.deliveryStep };
  });

  builder.addCase(
    FETCH_CHECKOUT_CART_DETAILS_PAYMENT,
    (state: ICheckoutState, action: any) => {
      const cartItems = action.payload as ICartResponse;

      const currentState = current(state);

      const { paymentInstruction, grandTotal, x_physicalStoreName, x_field1 } =
        cartItems;

      /**
       * This block of code is responsible for calculating the amount
       * deducted from grand total after applying gift card.
       */

      /**
       * By default allow multiple payments is set to false.
       *
       * This allows the regular payment flow by deleting the pi and add the new pi.
       */
      const { allowMultiplePayments, giftCardDeductedAmount } =
        calculateGiftCardBalance({
          giftCardDeductedAmount: -1,
          grandTotal,
          paymentInstruction,
          allowMultiplePayments: false,
        });

      const updatedState: ICheckoutState = {
        ...currentState,
        paymentStep: {
          ...currentState.paymentStep,
          cartItems,
          cartItemsLoading: false,
          allowMultiplePayments,
          giftCardDeductedAmount,
          x_physicalStoreName,
          x_field1,
        },
      };

      return { ...updatedState };
    }
  );

  builder.addCase(
    UPDATE_USABLE_PAYMENT_INFO_ACTION,
    (state: ICheckoutState, action: any) => {
      const usablePaymentInformation =
        action.payload as UsablePaymentInformationEntity[];

      const currentState = current(state);

      const updatedState: ICheckoutState = {
        ...currentState,
        paymentStep: {
          ...currentState.paymentStep,
          usablePaymentInfo: {
            loaded: true,
            paymentInfo: usablePaymentInformation,
          },
        },
      };

      return {
        ...updatedState,
      };
    }
  );

  builder.addCase(
    PAYMENT_STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS_ACTION,
    (state: ICheckoutState, action: any) => {
      const { PhysicalStore } = action.payload;

      if (PhysicalStore) {
        const currentState = current(state);

        const updatedState: ICheckoutState = {
          ...currentState,
          paymentStep: {
            ...currentState.paymentStep,
            deliveryStorePickup: {
              ...currentState.deliveryStep.deliveryStorePickup,
              storePickUpDetails: PhysicalStore,
              loading: false,
            },
          },
        };

        return {
          ...updatedState,
        };
      }
    }
  );

  /**
   * CASE PAYMENT_ASSIGN_STORE_SUCCESS_ACTION
   * Assign a selected pickup store
   */
  builder.addCase(
    PAYMENT_ASSIGN_STORE_SUCCESS_ACTION,
    (state: ICheckoutState, action: any) => {
      const storePickUp = action.payload as IStorePickupState;

      const currentState = current(state);

      const updatedState: ICheckoutState = {
        ...currentState,
        paymentStep: {
          ...currentState.paymentStep,
          storePickUp,
        },
      };

      return {
        ...updatedState,
      };
    }
  );

  builder.addCase(
    PAYMENT_USABLE_SHIPPING_INFO_SUCCESS_ACTION,
    (state: ICheckoutState, action: any) => {
      const response = action.payload as IGetUsableShippingResponse;

      const restrictedItems = response.restrictedItems
        ? response.restrictedItems
        : [];

      const currentState = current(state);

      if (response.orderItem[0] && response.orderItem[0]?.usableShippingMode) {
        const usableShippingMode = response.orderItem[0].usableShippingMode;

        const updatedState: ICheckoutState = {
          ...currentState,
          paymentStep: {
            ...currentState.paymentStep,
            usableShippingInfo: {
              ...currentState.paymentStep.usableShippingInfo,
              usableShippingMode,
              loading: false,
              sameDayDelivery: response.sameDayDelivery,
              restrictedItems,
            },
          },
        };

        return {
          ...updatedState,
        };
      }

      const updatedState: ICheckoutState = {
        ...currentState,
        paymentStep: {
          ...currentState.paymentStep,
          usableShippingInfo: {
            ...currentState.paymentStep.usableShippingInfo,
            loading: false,
            sameDayDelivery: response.sameDayDelivery,
            restrictedItems,
          },
        },
      };

      return {
        ...updatedState,
      };
    }
  );

  /**
   * @action UPDATE_PAYMENT_METHOD_ACTION
   * Updates the selected payment method
   */
  builder.addCase(
    UPDATE_PAYMENT_METHOD_ACTION,
    (state: ICheckoutState, action: any) => {
      const paymentInfo = action.payload;

      if (paymentInfo) {
        const currentState = current(state);

        const updatedState: ICheckoutState = {
          ...currentState,
          paymentStep: {
            ...currentState.paymentStep,
            paymentMethod: paymentInfo.paymentMethod,
          },
        };

        return {
          ...updatedState,
        };
      }
    }
  );

  builder.addCase(
    ENABLE_PAYMENT_CONTINUE_ACTION,
    (state: ICheckoutState, action: any) => {
      const currentState = current(state);

      const updatedState: ICheckoutState = {
        ...currentState,
        paymentStep: {
          ...currentState.paymentStep,
          isContinueEnabled: action.payload,
        },
      };

      return { ...updatedState };
    }
  );

  builder.addCase(CARD_DENIED_ACTION, (state: ICheckoutState, action: any) => {
    const currentState = current(state);

    const updatedState: ICheckoutState = {
      ...currentState,
      paymentStep: {
        ...currentState.paymentStep,
        cartDenied: action.payload,
      },
    };

    return {
      ...updatedState,
    };
  });

  builder.addCase(
    RESET_PAYMENT_STEP_ACTION,
    (state: ICheckoutState, action: any) => {
      const currentState = current(state);

      const updatedState: ICheckoutState = {
        ...currentState,
        paymentStep: initialCheckoutState.paymentStep,
      };

      return {
        ...updatedState,
      };
    }
  );

  builder.addCase(
    FETCH_CHECKOUT_CART_DETAILS_REVIEW,
    (state: ICheckoutState, action: any) => {
      const cartItems = action.payload as ICartResponse;

      const {
        orderItem,
        x_physicalStoreName,
        x_field1,
        x_carrier,
        x_carrierEstimateDelivery,
        x_code,
        x_physicalStoreId,
      } = cartItems;

      /**
       * @var noOfCartItems is determined based on the quantity of items in the count.
       *
       * Example: If the cart has 2 items, Item A - 1 Qty and Item B - 2 Qty
       * The total number of items in the cart will be 3 since Item A has 1 and Item B has 2 Qty.
       */
      const noOfCartItems = orderItem
        ? orderItem.reduce(
            (itemCount: number, item: IOrderItem) => +item.quantity + itemCount,
            0
          )
        : 0;

      const currentState = current(state);

      const {
        reviewStep: { freeItems },
      } = currentState;

      let currentFreeItems = { ...freeItems };

      orderItem.forEach(({ orderItemId, freeGift }) => {
        if (checkTruthy(freeGift)) {
          currentFreeItems = {
            ...currentFreeItems,
            [orderItemId]: true,
          };
        }
      });

      const { grandTotal, paymentInstruction } = cartItems;

      const { giftCardDeductedAmount } = calculateGiftCardBalance({
        giftCardDeductedAmount: -1,
        grandTotal,
        paymentInstruction,
      });

      const updatedState: ICheckoutState = {
        ...currentState,
        reviewStep: {
          ...currentState.reviewStep,
          cartItems,
          cartItemsLoading: false,
          noOfCartItems,
          giftCardDeductedAmount,
          freeItems: currentFreeItems,
          x_physicalStoreName,
          x_field1,
          x_physicalStoreId,
          shippingMethods: {
            method: x_code,
            time: x_carrierEstimateDelivery,
            cost: '',
            shippingId: '',
            shipModeCode: x_carrier,
          },
        },
      };

      return { ...updatedState };
    }
  );

  builder.addCase(
    FETCH_CART_PRODUCTS_REVIEW_ACTION,
    (state: ICheckoutState, action: any) => {
      const { contents } = action.payload as ICartProductsResponse;

      const currentState = current(state);

      const updatedState: ICheckoutState = {
        ...currentState,
        reviewStep: {
          ...currentState.reviewStep,
          cartProducts: contents,
        },
      };

      return {
        ...updatedState,
      };
    }
  );

  builder.addCase(
    REVIEW_USABLE_SHIPPING_INFO_SUCCESS_ACTION,
    (state: ICheckoutState, action: any) => {
      const response = action.payload as IGetUsableShippingResponse;

      const restrictedItems = response.restrictedItems
        ? response.restrictedItems
        : [];

      const currentState = current(state);

      if (response.orderItem[0] && response.orderItem[0]?.usableShippingMode) {
        const usableShippingMode = response.orderItem[0].usableShippingMode;

        const updatedState: ICheckoutState = {
          ...currentState,
          reviewStep: {
            ...currentState.reviewStep,
            usableShippingInfo: {
              ...currentState.reviewStep.usableShippingInfo,
              usableShippingMode,
              loading: false,
              sameDayDelivery: response.sameDayDelivery,
              restrictedItems,
            },
          },
        };

        return {
          ...updatedState,
        };
      }
    }
  );

  builder.addCase(
    GET_PICKUP_PERSON_REVIEW_SUCCESS_ACTION,
    (state: ICheckoutState, action: any) => {
      const { pickupPerson } = action.payload as IPickupPersonState;

      const currentState = current(state);

      if (pickupPerson && Array.isArray(pickupPerson)) {
        const updatedState: ICheckoutState = {
          ...currentState,
          reviewStep: {
            ...currentState.reviewStep,
            pickupPerson,
          },
        };

        return {
          ...updatedState,
        };
      } else {
        const updatedState: ICheckoutState = {
          ...currentState,
          reviewStep: {
            ...currentState.reviewStep,
            pickupPerson: [],
          },
        };

        return {
          ...updatedState,
        };
      }
    }
  );

  builder.addCase(
    UPDATE_REVIEW_SHIPPING_METHOD_DATA_ACTION,
    (state: ICheckoutState, action: any) => {
      const shippingMethodData = action.payload as IShippingMethods;

      if (shippingMethodData) {
        const { method, time, cost, shippingId, shipModeCode } =
          shippingMethodData;

        const shippingMethods: IShippingMethods = {
          method,
          time,
          cost,
          shippingId,
          shipModeCode,
        };

        const currentState = current(state);

        const updatedState: ICheckoutState = {
          ...currentState,
          reviewStep: {
            ...currentState.reviewStep,
            shippingMethods,
          },
        };

        return { ...updatedState };
      }
    }
  );

  builder.addCase(RESET_REVIEW_STEP_ACTION, (state: ICheckoutState) => {
    const currentState = current(state);

    const updatedState: ICheckoutState = {
      ...currentState,
      reviewStep: initialCheckoutState.reviewStep,
    };

    return {
      ...updatedState,
    };
  });

  builder.addCase(
    CARDINAL_ORDER_REQUEST_SUCCESS_ACTION,
    (state: ICheckoutState, action: any) => {
      const cardinalOrder = action.payload as ICardinalOrderResponse;

      const currentState = current(state);

      const updatedState: ICheckoutState = {
        ...currentState,
        reviewStep: {
          ...currentState.reviewStep,
          cardinalOrder,
        },
      };

      return {
        ...updatedState,
      };
    }
  );

  builder.addCase(RESET_ASSIGN_STORE_ACTION, (state: ICheckoutState) => {
    const currentState = current(state);

    const updatedState: ICheckoutState = {
      ...currentState,
      deliveryStep: {
        ...currentState.deliveryStep,
        storePickUp: initStorePickUp,
      },
      paymentStep: {
        ...currentState.paymentStep,
        storePickUp: initStorePickUp,
      },
      reviewStep: {
        ...currentState.reviewStep,
        storePickUp: initStorePickUp,
      },
    };

    return {
      ...updatedState,
    };
  });

  builder.addCase(
    UPDATE_EMAIL_OPTIN_STATUS,
    (state: ICheckoutState, action: any) => {
      const currentState = current(state);

      const updatedState: ICheckoutState = {
        ...currentState,
        reviewStep: {
          ...currentState.reviewStep,
          emailOptin: action.payload,
        },
      };

      return {
        ...updatedState,
      };
    }
  );

  builder.addCase(
    PAYPAL_PROCESS_LOADING_ACTION,
    (state: ICheckoutState, action: any) => {
      const currentState = current(state);

      const updatedState: ICheckoutState = {
        ...currentState,
        reviewStep: {
          ...currentState.reviewStep,
          processing: action.payload,
        },
      };
      return {
        ...updatedState,
      };
    }
  );
});

export { checkoutReducer };
